<template>
	<div>

		<div id="bodyContainer">

		<div id="main-name">
			<h1>f-a-t.cz</h1>
		</div>

		<div id="bubble">
			<svg viewBox="0 0 255.55 159">
				<a href="o-nas.html">
					<path id="bubblePath" d="M255.55,75.45a55.48,55.48,0,0,0-4.57-22c-3.86-9-11.39-21.37-17.9-27.42-9.41-8.75-24.43-18.44-52-18.44-9.2,0-22.07,5-22.07,5S139.83-1.63,116.19,0C89.52,1.84,72.75,9.65,61.92,20.24c-10.31,10.09-15.43,22-18.79,29.26a70.94,70.94,0,0,0-6.57,29.86c0,8.25,1.44,21.7,4,29.11-9.11,1.74-22.28,0-40.61-11.3,0,0,6.92,26.2,51.12,30.88A78.41,78.41,0,0,0,114,158.87a79.45,79.45,0,0,0,46.34-14.72,92.2,92.2,0,0,0,17.85,1.75c42.74,0,77.4-34.59,77.4-70.45" transform="translate(0 0.13)"/>
				</a>
			</svg>
			<p>film<br>animace<br>teorie</p>
		</div>

		<div id="main-content">

			<div id="jehly">
				<h1 class="uppercase">animators to animators</h1>
			</div>

				<div id="main-block">
					<div id="main-video">
						<!--div @mouseenter="pauseVid" @mouseleave="playVid" v-touch:tap.start="pauseVid" v-touch:tap.end="playVid"></div-->
						<div @mouseenter=" isMobile ? false : pauseVid() " @mouseleave=" isMobile ? false : playVid() " @touchstart=" testM() " @touchend=" testM() "></div>
						<video id="main-video-video" autoplay muted loop playsinline>
							<source src="./assets/img/video/final_chuze_alpha.webm" type="video/webm">
							Your browser does not support the video tag.
						</video>
						<video id="main-video-text" autoplay muted loop playsinline>
							<source src="./assets/img/video/fat_chuze_orion2.webm" type="video/webm">
							Your browser does not support the video tag.
						</video>
					</div>

						<div id="bigSymbols3">
							<div id="bigSymbol1" class="bigSymbol1Animate" :class="{ bigSymbol1AnimateRun: bigSymbol1AnimationRun }">
								<svg class="bigSymbolHighlight" viewBox="0 0 207.66 208.08">
									<polygon
										@click="goToDatabaze"
										@mouseenter=" isMobile ? false : bigSymbol1AnimationRun = !bigSymbol1AnimationRun"
										@mouseleave="isMobile ? false : bigSymbol1AnimationRun = false"
										@touchstart=" bigSymbol1AnimationRun = !bigSymbol1AnimationRun "
										@touchend=" bigSymbol1AnimationRun = false "
										points="103.83 34.35 113.64 10 118.21 35.87 132.82 14.11 131.96 40.37 150.75 22.15 144.48 47.66 166.62 33.77 155.22 57.41 179.74 48.46 163.72 69.19 189.55 65.58 169.6 82.5 195.61 84.38 172.6 96.75 197.66 104.04 172.6 111.32 195.61 123.7 169.6 125.57 189.55 142.5 163.72 138.88 179.74 159.62 155.22 150.67 166.62 174.31 144.48 160.42 150.75 185.93 131.96 167.7 132.82 193.97 118.21 172.2 113.64 198.08 103.83 173.73 94.02 198.08 89.45 172.2 74.83 193.97 75.7 167.7 56.91 185.93 63.18 160.42 41.05 174.31 52.44 150.67 27.92 159.62 43.94 138.88 18.11 142.5 38.06 125.57 12.05 123.7 35.05 111.32 10 104.04 35.05 96.75 12.05 84.38 38.06 82.5 18.11 65.58 43.94 69.19 27.92 48.46 52.44 57.41 41.05 33.77 63.18 47.66 56.91 22.15 75.7 40.37 74.83 14.11 89.45 35.87 94.02 10 103.83 34.35"/>
								</svg>
								<p class="uppercase">DATA<br>BÁZE</p>
							</div>
							<div id="bigSymbol2" class="bigSymbol2Animate" :class="{ bigSymbol2AnimateRun: bigSymbol2AnimationRun }">
								<svg class="bigSymbolHighlight" viewBox="0 0 177.99 177.99">
									<path
										@click="goToDatabaze"
										@mouseenter=" isMobile ? false : bigSymbol2AnimationRun = !bigSymbol2AnimationRun"
										@mouseleave="isMobile ? false : bigSymbol2AnimationRun = false"
										@touchstart=" bigSymbol2AnimationRun = !bigSymbol2AnimationRun "
										@touchend=" bigSymbol2AnimationRun = false "
										d="M88.5,167A78.5,78.5,0,1,0,10,88.5,78.49,78.49,0,0,0,88.5,167" transform="translate(0.5 0.5)"/>
								</svg>
								<p class="uppercase bigSymbol2White" :class="{ bigSymbol2Black: bigSymbol2AnimationRun }">LIDÍ</p>
							</div>
							<div id="bigSymbol3" class="bigSymbol3Animate" :class="{ bigSymbol3AnimateRun: bigSymbol3AnimationRun }">
								<svg class="bigSymbolHighlight" viewBox="0 0 182.05 161.63">
									<polygon
										@click="goToDatabaze"
										@mouseenter=" isMobile ? false : bigSymbol3AnimationRun = !bigSymbol3AnimationRun"
										@mouseleave="isMobile ? false : bigSymbol3AnimationRun = false"
										@touchstart=" bigSymbol3AnimationRun = !bigSymbol3AnimationRun "
										@touchend=" bigSymbol3AnimationRun = false "
										points="131.54 10 50.51 10 10 80.81 50.51 151.63 131.54 151.63 172.05 80.81 131.54 10"/>
								</svg>
								<p class="uppercase">OD<br>FOCHU</p>
							</div>
						</div>

					<div id="main-texts-podcasts">
						<div id="main-texts">
							<h3 id="clanky-main-name" class="clankyHighlight uppercase">
								<a href="clanky.html"
								@mouseenter=" isMobile ? false : mainTextsLineAnimationPause = !mainTextsLineAnimationPause"
								@mouseleave=" isMobile ? false : mainTextsLineAnimationPause = false"
								@touchstart=" mainTextsLineAnimationPause = !mainTextsLineAnimationPause "
								@touchend=" mainTextsLineAnimationPause = false ">
									články
								</a>
							</h3>
							<div id="main-texts-more">
								<div id="main-texts-1">
									<h3 class="clankyHighlight"><a href="clanky.html"
									@mouseenter=" isMobile ? false : mainTextsLineAnimationPause = !mainTextsLineAnimationPause"
									@mouseleave=" isMobile ? false : mainTextsLineAnimationPause = false"
									@touchstart=" mainTextsLineAnimationPause = !mainTextsLineAnimationPause "
									@touchend=" mainTextsLineAnimationPause = false ">
										interviews</a></h3>
									<div class="main-texts-line-wrap">
										<div class="main-texts-line"
											:class="{ mainTextsLineAnimationPaused: mainTextsLineAnimationPause }"
											@mouseenter=" isMobile ? false : mainTextsLineAnimationPause = !mainTextsLineAnimationPause"
											@mouseleave=" isMobile ? false : mainTextsLineAnimationPause = false"
											@touchstart=" mainTextsLineAnimationPause = !mainTextsLineAnimationPause "
											@touchend=" mainTextsLineAnimationPause = false "></div>
										<div class="main-texts-line"
											:class="{ mainTextsLineAnimationPaused: mainTextsLineAnimationPause }"
											@mouseenter=" isMobile ? false : mainTextsLineAnimationPause = !mainTextsLineAnimationPause"
											@mouseleave=" isMobile ? false : mainTextsLineAnimationPause = false"
											@touchstart=" mainTextsLineAnimationPause = !mainTextsLineAnimationPause "
											@touchend=" mainTextsLineAnimationPause = false "></div>
									</div>
								</div>
								<div id="main-texts-2">
									<div class="main-texts-line-wrap">
										<div class="main-texts-line"
											:class="{ mainTextsLineAnimationPaused: mainTextsLineAnimationPause }"
											@mouseenter=" isMobile ? false : mainTextsLineAnimationPause = !mainTextsLineAnimationPause"
											@mouseleave=" isMobile ? false : mainTextsLineAnimationPause = false"
											@touchstart=" mainTextsLineAnimationPause = !mainTextsLineAnimationPause "
											@touchend=" mainTextsLineAnimationPause = false ">
										</div>
										<div class="main-texts-line"
											:class="{ mainTextsLineAnimationPaused: mainTextsLineAnimationPause }"
											@mouseenter=" isMobile ? false : mainTextsLineAnimationPause = !mainTextsLineAnimationPause"
											@mouseleave=" isMobile ? false : mainTextsLineAnimationPause = false"
											@touchstart=" mainTextsLineAnimationPause = !mainTextsLineAnimationPause "
											@touchend=" mainTextsLineAnimationPause = false ">
										</div>
										<div class="main-texts-line"
											:class="{ mainTextsLineAnimationPaused: mainTextsLineAnimationPause }"
											@mouseenter=" isMobile ? false : mainTextsLineAnimationPause = !mainTextsLineAnimationPause"
											@mouseleave=" isMobile ? false : mainTextsLineAnimationPause = false"
											@touchstart=" mainTextsLineAnimationPause = !mainTextsLineAnimationPause "
											@touchend=" mainTextsLineAnimationPause = false ">
										</div>
										<div class="main-texts-line"
											:class="{ mainTextsLineAnimationPaused: mainTextsLineAnimationPause }"
											@mouseenter=" isMobile ? false : mainTextsLineAnimationPause = !mainTextsLineAnimationPause"
											@mouseleave=" isMobile ? false : mainTextsLineAnimationPause = false"
											@touchstart=" mainTextsLineAnimationPause = !mainTextsLineAnimationPause "
											@touchend=" mainTextsLineAnimationPause = false ">
										</div>
										<div class="main-texts-line"
											:class="{ mainTextsLineAnimationPaused: mainTextsLineAnimationPause }"
											@mouseenter=" isMobile ? false : mainTextsLineAnimationPause = !mainTextsLineAnimationPause"
											@mouseleave=" isMobile ? false : mainTextsLineAnimationPause = false"
											@touchstart=" mainTextsLineAnimationPause = !mainTextsLineAnimationPause "
											@touchend=" mainTextsLineAnimationPause = false ">
										</div>
									</div>
									<h3 class="uppercase clankyHighlight"><a href="clanky.html"
											@mouseenter=" isMobile ? false : mainTextsLineAnimationPause = !mainTextsLineAnimationPause"
											@mouseleave=" isMobile ? false : mainTextsLineAnimationPause = false"
											@touchstart=" mainTextsLineAnimationPause = !mainTextsLineAnimationPause "
											@touchend=" mainTextsLineAnimationPause = false ">reporty<br>{{'\xa0'}}{{'\xa0'}}a recenze</a><br></h3>
								</div>
								<div id="main-texts-3">
									<h3 class="clankyHighlight"><a href="clanky.html"
											@mouseenter=" isMobile ? false : mainTextsLineAnimationPause = !mainTextsLineAnimationPause"
											@mouseleave=" isMobile ? false : mainTextsLineAnimationPause = false"
											@touchstart=" mainTextsLineAnimationPause = !mainTextsLineAnimationPause "
											@touchend=" mainTextsLineAnimationPause = false ">tipy &amp; triky</a></h3>
									<div class="main-texts-line-wrap">
										<div class="main-texts-line"
											:class="{ mainTextsLineAnimationPaused: mainTextsLineAnimationPause }"
											@mouseenter=" isMobile ? false : mainTextsLineAnimationPause = !mainTextsLineAnimationPause"
											@mouseleave=" isMobile ? false : mainTextsLineAnimationPause = false"
											@touchstart=" mainTextsLineAnimationPause = !mainTextsLineAnimationPause "
											@touchend=" mainTextsLineAnimationPause = false ">
										</div>
										<div class="main-texts-line"
											:class="{ mainTextsLineAnimationPaused: mainTextsLineAnimationPause }"
											@mouseenter=" isMobile ? false : mainTextsLineAnimationPause = !mainTextsLineAnimationPause"
											@mouseleave=" isMobile ? false : mainTextsLineAnimationPause = false"
											@touchstart=" mainTextsLineAnimationPause = !mainTextsLineAnimationPause "
											@touchend=" mainTextsLineAnimationPause = false ">
										</div>
									</div>
								</div>
								<div id="main-texts-4">
									<h3 class="uppercase clankyHighlight">
										<a href="clanky.html"
											@mouseenter=" isMobile ? false : mainTextsLineAnimationPause = !mainTextsLineAnimationPause"
											@mouseleave=" isMobile ? false : mainTextsLineAnimationPause = false"
											@touchstart=" mainTextsLineAnimationPause = !mainTextsLineAnimationPause "
											@touchend=" mainTextsLineAnimationPause = false ">eseje, postupy práce
										</a>
									</h3>
								</div>
								<div id="main-texts-5">
									<div class="main-texts-line-wrap">
										<div class="main-texts-line"
											:class="{ mainTextsLineAnimationPaused: mainTextsLineAnimationPause }"
											@mouseenter=" isMobile ? false : mainTextsLineAnimationPause = !mainTextsLineAnimationPause"
											@mouseleave=" isMobile ? false : mainTextsLineAnimationPause = false"
											@touchstart=" mainTextsLineAnimationPause = !mainTextsLineAnimationPause "
											@touchend=" mainTextsLineAnimationPause = false ">
										</div>
										<div class="main-texts-line"
											:class="{ mainTextsLineAnimationPaused: mainTextsLineAnimationPause }"
											@mouseenter=" isMobile ? false : mainTextsLineAnimationPause = !mainTextsLineAnimationPause"
											@mouseleave=" isMobile ? false : mainTextsLineAnimationPause = false"
											@touchstart=" mainTextsLineAnimationPause = !mainTextsLineAnimationPause "
											@touchend=" mainTextsLineAnimationPause = false ">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div id="podcasty-buttons">
							<div>
								<svg id="button1" viewBox="0 0 156.99 156.99">
									<path @click="goToPodcasty" class="podcastyDefault1Class" :class="{ podcastyBlueClass: podcastyBlue }" @mouseenter="podcastyBlue = !podcastyBlue" @mouseleave="podcastyBlue = false" d="M78.5,157A78.5,78.5,0,1,0,0,78.5,78.49,78.49,0,0,0,78.5,157"/>
								</svg>
								<svg id="button2" viewBox="0 0 57.04 65.83">
									<polygon @click="playPodcast" class="podcastyDefault2Class" :class="{ podcastyBlueClass: podcastyBlue && (podcastPlayVar == 0 || podcastPlayVar == 1), podcastyGreenClass: podcastPlayVar == 2}" @mouseenter="podcastyBlue = !podcastyBlue" @mouseleave="podcastyBlue = false" points="57.04 32.91 0 0 0 65.83 57.04 32.91"/>
								</svg>
								<audio id="podcast-intro">
									<source src="./assets/img/intro.mp4" type="audio/mpeg">
								Your browser does not support the audio element.
								</audio>
								<svg id="button3" viewBox="0 0 49.41 65.83">
									<rect @click="pausePodcast" class="podcastyDefault3Class" :class="{ podcastyBlueClass: podcastyBlue && podcastPlayVar == 2, podcastyGreenClass: podcastPlayVar == 1 }" @mouseenter="podcastyBlue = !podcastyBlue" @mouseleave="podcastyBlue = false" width="20.87" height="65.83"/>
									<rect @click="pausePodcast" class="podcastyDefault3Class" :class="{ podcastyBlueClass: podcastyBlue && podcastPlayVar == 2, podcastyGreenClass: podcastPlayVar == 1 }" @mouseenter="podcastyBlue = !podcastyBlue" @mouseleave="podcastyBlue = false" x="28.55" width="20.87" height="65.83"/>
								</svg>
							</div>
							<h3 id="podcasty-buttons-text" class="podcastyDefault4Class" :class="{ podcastyBlueClass: podcastyBlue }" @click="goToPodcasty" @mouseenter="podcastyBlue = !podcastyBlue" @mouseleave="podcastyBlue = false" >podcasty</h3>
						</div>
					</div>
				</div>

		</div>

		<div id="marqueWrap">
			<div class="marquee" id="marqueeElement">
				<div class="track" id="marqueeTrack">
					<div class="content" id="marqueeContent">

						<marquee-text :repeat="15" :duration="45" :paused="isPaused" @mouseenter="isPaused = !isPaused" @mouseleave="isPaused = false" @touchstart="isPaused = !isPaused" @touchend=" isPaused = false">
							<h2 class="uppercase" v-for="marqueeLink in marqueeLinks" :key="marqueeLink.message">
								<a :href="marqueeLink.elHref" :class="marqueeLink.elClass">
									<img :src="require('./assets/img/symbols/' + marqueeLink.imgSrc + '.svg')">
									{{marqueeLink.message}}
								</a>
							</h2>
						</marquee-text>

					</div>
				</div>
			</div>
		</div>

	</div>

	</div>
</template>

<script>

import shuffle from 'lodash/shuffle';
import MarqueeText from 'vue-marquee-text-component'

import $ from 'jquery'

export default {

	data() {
		return {
			marqueeLinks: [
				{ elHref: 'clanky/animateka-focus-anidok.html', imgSrc: 'symbol1', elClass: 'onHoverOrange', message: 'ANIMATEKA: FOCUS ANIDOK' },
				{ elHref: 'podcasty/ondrej-moravec-&-bara-anna-stejskalova.html', imgSrc: 'symbol2', elClass: 'onHoverBlue', message: 'JAK BY VYPADAL VÁŠ DÉMON?' },
				{ elHref: 'databaze/michaela_mihalyi.html', imgSrc: 'symbol3', elClass: 'onHoverGreen', message: 'MICHAELA MIHALYI' },
				{ elHref: 'databaze/matous_vyhnanek.html', imgSrc: 'symbol3', elClass: 'onHoverGreen', message: 'MATOUŠ VYHNÁNEK' },
				{ elHref: 'databaze/adela_krizovenska.html', imgSrc: 'symbol3', elClass: 'onHoverGreen', message: 'ADELA KRIŽOVENSKÁ' }
			],
			isMobile: false,
			videoClicked: false,
			isPaused: false,
			bigSymbol1AnimationRun: false,
			bigSymbol2AnimationRun: false,
			bigSymbol3AnimationRun: false,
			mainTextsLineAnimationPause: false,
			podcastyBlue: false,
			alphaVideoWorks: true,
			podcastPlayVar: 0,
			windowWidth: window.innerWidth,
			windowHeight: window.innerHeight,
		};
	},
	async created() {
		this.marqueeLinks = shuffle(this.marqueeLinks);
		window.addEventListener('resize', this.checkWindowSize);
		window.addEventListener('resize', this.mainBlockScale);
	},
	methods: {
		checkWindowSize() {
			this.windowWidth = window.innerWidth;
			this.windowHeight = window.innerHeight;
		},
		mainBlockScale () {
				var mainBlockElement = document.getElementById("main-block");
				var scale, mainBlockElementOffsetTop, bodyContainerHeight, marqueeTop;
				if (this.windowWidth > 500 && this.windowWidth > this.windowHeight) {
					this.isMobile = false;
					mainBlockElementOffsetTop = this.windowWidth/100*11 + 89 + 20;
					scale = (this.windowHeight - (this.windowWidth/100*11 + 89 + 87 + 50)) / 880;
					bodyContainerHeight = "100vh";
				} else {
					this.isMobile = true;
					mainBlockElementOffsetTop = this.windowWidth/100*33.2 + 14;
					scale = ((this.windowWidth - 40) / 1120);
					document.getElementById("main-content").style.height =  this.windowWidth/100*12 + 28 + ( 1780 * scale ) + "px";
					if (this.windowWidth > 500) {
						marqueeTop =  this.windowWidth/100*33.2 + 14 + 106 + ( 1780 * scale );
					} else {
						marqueeTop =  this.windowWidth/100*33.2 + 14 + 26 + ( 1780 * scale );
					}
					if(marqueeTop < this.windowHeight) {
						marqueeTop =  this.windowHeight - 55;
					}
					bodyContainerHeight = marqueeTop + "px";
				}
				document.getElementById("bodyContainer").style.height =  bodyContainerHeight;
				document.getElementById("marqueWrap").style.top =  marqueeTop + "px";
				mainBlockElement.style.top = mainBlockElementOffsetTop + "px";
				mainBlockElement.style.transform = "scale(" + scale + ")";
		},
		checkIfVideoPlays() {
			var vid1 = document.getElementById("main-video-video");
			var vid2 = document.getElementById("main-video-text");
			if ( vid1.canPlayType('video/webm') == "") {
				this.alphaVideoWorks = false;
				$( "#main-video-text" ).after( `
						<video id="main-video-video-noAlpha" autoplay muted loop playsinline>
						  	<source src="img/video/final_chuze_noAlpha.mp4" type="video/mp4">
							Your browser does not support the video tag.
						</video>
						<video id="main-video-text-noAlpha" autoplay muted loop playsinline>
						  	<source src="img/video/fat_chuze_orion2_noAlpha.mp4" type="video/mp4">
							Your browser does not support the video tag.
						</video>
					` );
				vid1.remove();
				vid2.remove();
				document.getElementById("main-name").style.zIndex = "1000";
				document.getElementById("jehly").style.zIndex = "1000";
				vid1 = document.getElementById("main-video-video-noAlpha");
				vid2 = document.getElementById("main-video-text-noAlpha");
			}
		},
		playVid() {
			var vid1 = document.getElementById("main-video-video");
			var vid2 = document.getElementById("main-video-text");

			if (!this.alphaVideoWorks) {
				vid1 = document.getElementById("main-video-video-noAlpha");
				vid2 = document.getElementById("main-video-text-noAlpha");
			}

			vid1.muted = true;
			vid2.muted = true;
			vid1.play();
			vid2.play();
			vid1.style.opacity = "1";
			vid2.style.opacity = "0";
		},
		pauseVid() {
			var vid1 = document.getElementById("main-video-video");
			var vid2 = document.getElementById("main-video-text");

			if (!this.alphaVideoWorks) {
				vid1 = document.getElementById("main-video-video-noAlpha");
				vid2 = document.getElementById("main-video-text-noAlpha");
			}

			vid1.pause();
			vid2.pause();
			vid1.style.opacity = "0";
			vid2.style.opacity = "1";
		},
		testM() {
			if (this.isMobile) {
				if (this.videoClicked) {
					this.playVid();
					this.videoClicked = false;
				} else {
					this.pauseVid();
					this.videoClicked = true;
				}
			}
		},
		playPodcast() {
			var podcast = document.getElementById("podcast-intro");
			podcast.play();
			this.podcastPlayVar = 2;
		},
		pausePodcast() {
			var podcast = document.getElementById("podcast-intro");
			podcast.pause();
			this.podcastPlayVar = 1;
		},
		goToDatabaze() {
			window.location.href = './databaze.html';
		},
		goToPodcasty() {
			window.location.href = './podcasty.html';
		}
	},
	mounted:function(){
			window.addEventListener('resize', this.checkWindowSize);
			this.mainBlockScale();
			this.checkIfVideoPlays();
			this.playVid();
	},
	components: {
		MarqueeText
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.checkWindowSize);
		window.removeEventListener('resize', this.mainBlockScale);
	}
}
</script>

<style>
	@import	'./assets/reset.css';
	@import	'./assets/style.less';
	@import	'./assets/style_main.less';
</style>
